<template>
  <div class="container" @click="goToHome">
    <div class="content pop" v-if="isLogo"
      :style="{ animationDelay: `0s`, animationDuration: `0.2s` }"
      >
      <img key="logo" class="rotate" src="@/assets/logo-blue-rotation-centered.png" />
    </div>
    <div class="content pop" 
    :style="{ animationDelay: `0s`, animationDuration: `0.2s` }"
    v-else>
      <img key="another-image" src="@/assets/home-sun-1.png" />
      <p>Votre installation solaire<br/>⟼ clés en main.</p>
    </div>
    <div class="footer">
      Made by Des Choses Pareilles - Version {{ $store.state.packageVersion }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogo: true, // Toggle between true/false to switch images
    };
  },
  mounted() {
    // Switch images every 5 seconds
    this.interval = setInterval(() => {
      this.isLogo = !this.isLogo;
    }, 5000);
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval);
  },
  methods: {
    goToHome() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: $secondary-color;
}

p {
  font-size: 4em;
}

img {
  width: 40vw;
}

.rotate {
  animation: rotateAnticlockwise 5s infinite;
}

/* Keyframes for rotation */
@keyframes rotateAnticlockwise {
  30% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-360deg); /* Complete rotation anticlockwise */
  }
  100% {
    transform: rotate(-360deg); /* Complete rotation anticlockwise */
  }
}

.pop {
  transform: scale(0);
  animation-name: pop-scale;
  animation-fill-mode: forwards;
  transition: background-color 0.3s ease;
}

/* Animation de pop */
@keyframes pop-scale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
