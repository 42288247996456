<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@700&display=swap');

body {
  margin: 0px;
  overflow: hidden;
}

// p{
//   font-family: 'EB Garamond', serif;
//   font-size: 35px;
//   font-weight: 400;
//   line-height: 1.4;
// }

div.container{
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.content{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  max-width: 50em;
}

div.footer{
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: smaller;
  color: $light-text-color;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary-color;
}
</style>
